import isValid from 'date-fns/isValid';
import parseJSON from 'date-fns/parseJSON';

const getDateFromDayString = (dayString: string): Date | null => {
  if (!dayString) {
    return null;
  }
  const dateString = `${dayString}T12:00:00+02:00`;
  const date = parseJSON(dateString);
  return isValid(date) ? date : null;
};

export default getDateFromDayString;
