import { IVarEvent } from 'library/Competitions';
import { rem } from 'polished';
import React from 'react';
import Separator from '@sportnet/ui/lib/Separator';
import differenceInHours from 'date-fns/differenceInHours';
import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import sk from 'date-fns/locale/sk';
import styled from 'styled-components';

const Wrapper = styled.div``;

const VarEventWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const VarEventDetailsWrapper = styled.div`
  width: 100%;
`;

const VerdictDeilimiter = styled(Separator)`
  margin-top: ${rem(-15)};
  margin-bottom: ${rem(15)};
`;

const Circle = styled.div`
  width: ${rem(8)};
  height: ${rem(8)};
  border: 1px solid ${({ theme }) => theme.input.borderColor};
  border-radius: 50%;
  margin-right: ${rem(32)};
  flex: 0 0 auto;
`;

const Delimiter = styled.div`
  height: ${rem(32)};
  border-left: 1px solid ${({ theme }) => theme.input.borderColor};
  margin-left: ${rem(4)};
`;

const VarEventDescription = styled.div`
  font-size: ${rem(14)};
  font-weight: 600;
  margin-bottom: ${rem(4)};
`;

const VarEventTime = styled.div`
  font-size: ${rem(10)};
  font-weight: normal;
  color: ${({ theme }) => theme.color.fadedText};
`;

interface IOwnProps {
  varEvents: IVarEvent[];
}

export const MatchVarEventsList: React.FC<IOwnProps> = ({ varEvents }) => {
  const now = new Date();

  const HOURS_AFTER_SWITCH_FORMAT = 4;

  const getVarEventTimeString = (varEventTimestamp: Date): string => {
    if (differenceInHours(now, varEventTimestamp) > HOURS_AFTER_SWITCH_FORMAT) {
      return format(varEventTimestamp, 'dd.MM.yyyy HH:mm:ss');
    }
    return formatDistanceToNow(varEventTimestamp, {
      includeSeconds: true,
      addSuffix: true,
      locale: sk,
    });
  };

  return (
    <Wrapper>
      {varEvents.map((varEvent, idx) => (
        <React.Fragment key={`varEvent-${idx}`}>
          <VarEventWrapper>
            <Circle />
            <VarEventDetailsWrapper>
              {idx > 0 && varEvent.verdict && <VerdictDeilimiter />}
              <VarEventDescription>{varEvent.description}</VarEventDescription>
              {varEvent.datetime ? (
                <VarEventTime>
                  {getVarEventTimeString(new Date(varEvent.datetime))}
                </VarEventTime>
              ) : (
                '-'
              )}
            </VarEventDetailsWrapper>
          </VarEventWrapper>
          {idx < varEvents.length - 1 && <Delimiter />}
        </React.Fragment>
      ))}
    </Wrapper>
  );
};

export default MatchVarEventsList;
