import { rem } from 'polished';
import Loader from '@sportnet/ui/lib/Loader';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${rem(16)};
  margin-bottom: ${rem(16)};
`;

const QueryLoader: React.FC = () => (
  <Wrapper>
    <Loader size="xl" />
  </Wrapper>
);

export default QueryLoader;
