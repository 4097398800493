import { ApiError } from 'library/Competitions';

const getApiErrorMessage = (e: unknown): string => {
  if (!e) {
    return 'Unknown error.';
  }
  if ((e as ApiError).details) {
    const { details } = e as ApiError;
    return `${details.statusCode || details.status} ${details.name}${
      details.description ? ` - ${details.description}` : ''
    }.`;
  } else if ((e as Error).message) {
    return (e as Error).message;
  } else {
    return 'Unknown error.';
  }
};

export default getApiErrorMessage;
