import {
  Theme,
  getTheme as getSportnetUiTheme,
} from '@sportnet/ui/lib/Themes/baseTheme';

type Color = string;
type Size = number;

export interface ITheme extends Theme {
  app: {
    maxWidth?: Size;
    padding?: Size;
    textColor: Color;
    bgColor?: Color;
  };
}

type RecursivePartial<T> = { [P in keyof T]?: RecursivePartial<T[P]> };

export const getTheme = (override: RecursivePartial<ITheme> = {}): ITheme => {
  const app = {
    maxWidth: 1200,
    padding: 15,
    textColor: '#000',
    bgColor: '#fff',
    ...override.app,
  };

  return {
    ...getSportnetUiTheme(override),
    app,
  };
};

export const theme = getTheme({
  fontFamily: "'Barlow', sans-serif",
  textColor: '#000',
  grid: {
    gutterWidth: 12,
  },
  color: {
    fadedText: '#888',
  },
});
