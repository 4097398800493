/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type Error = {
  status: number;

  name: string;

  description?: string;

  payload?: {};

  userinfo?: string;
};
export type Season = {
  _id: string;

  appSpace: string;

  name: string;

  dateFrom: string;

  dateTo: string;

  sport: string;
};
export type AgeCategory = {
  _id: string;

  appSpace: string;

  name: string;

  gender?: string;

  maleAgeCategoryFrom?: string;

  femaleAgeCategoryFrom?: string;

  maleAgeCategoryTo?: string;

  femaleAgeCategoryTo?: string;
};
export type MatchManagerRole =
  | 'VAR'
  | 'NOMINATIONS'
  | 'PROTOCOL'
  | 'DATACOLLECTOR';
export type SportObject = {
  _id: string;

  appSpace: string;

  name: string;

  sport: string;

  street: string;

  number: string;

  owner: string;

  zip: string;

  city: string;

  country: string;

  sportGrounds?: Array<{
    _id: string;

    name: string;

    sportGroundType: string;

    additionalData?: {};
  }>;
};
export type SportGround = {
  _id: string;

  name: string;

  sportGroundType: string;

  additionalData?: {};
};
export type Team = {
  _id: string;

  appSpace: string;

  organization?: {
    _id: string;

    organization_id: string;

    organization_name?: string;

    name: string;

    country?: string;

    logo_public_url?: string;
  };

  logo?: {
    media_url: string;

    public_url: string;
  };

  smarttags?: Array<{
    key: string | number;

    values: Array<{
      key: string | number;

      title?: string | null;
    }>;
  }>;

  name: string;

  displayName: string;

  detailUrl?: string;

  gender?: string;

  sport_sector: string;

  athletes?: Array<{
    sportnetUser: {
      _id: string;

      name: string;
    };

    additionalData?: {};
  }>;
};
export type Competition = {
  _id: string;

  competitionGroupId: string;

  homepageSectionIdOrUniqId?: string;

  appSpace: string;

  name: string;

  identifier?: string;

  note?: string;

  season: {
    _id: string;

    appSpace: string;

    name: string;

    dateFrom: string;

    dateTo: string;

    sport: string;
  };

  sport: string;

  parts?: Array<{
    _id: string;

    name: string;

    rounds?: Array<{
      _id: string;

      name: string;

      dateFrom: string;

      dateTo: string;
    }>;

    rules?: {};

    settings?: {};

    signup?: boolean;

    resultsTable?: {
      results: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;

          organization?: {
            _id: string;

            name: string;

            organization_id: string;

            organization_name: string;

            country?: string;
          };

          resignation?: boolean;
        };

        stats: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        homeStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        awayStats?: {
          matches: {
            played: number;

            won: number;

            draw: number;

            lost: number;
          };

          goals: {
            given: number;

            received: number;
          };

          points: number;
        };

        matches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        homeMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;

        awayMatches?: Array<{
          team: {
            _id: string;

            name: string;

            displayName: string;
          };

          result: 'W' | 'L' | 'D';

          homeaway: 'home' | 'away';

          matchId: string;

          score: string;
        }>;
      }>;

      players?: Array<{
        _id: string;

        teamId: string;

        name: string;

        stats: {
          match_starts?: number;

          match_appearances?: number;

          goals?: number;

          assists?: number;

          yellow_cards?: number;

          red_cards?: number;

          substitutions?: number;

          own_goals?: number;
        };
      }>;
    };

    matches_count?: number;

    matchesCountByTeamId?: {};

    dateFrom: string;

    dateTo: string;
  }>;

  signup?: boolean;

  publication?: {};

  smarttags?: {
    key: string | number;

    values: Array<{
      key: string | number;

      title?: string | null;
    }>;
  };

  smarttagsByAppSpace?: Array<{
    appSpace: string;

    smarttags: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };
  }>;

  teams?: Array<{
    _id: string;

    name: string;

    appSpace: string;
  }>;
};
export type Match = {
  _id: string;

  appSpace: string;

  smarttags?: {
    key: string | number;

    values: Array<{
      key: string | number;

      title?: string | null;
    }>;
  };

  perex?: string;

  smarttagsByAppSpace?: Array<{
    appSpace: string;

    smarttags: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };
  }>;

  teams: Array<{
    _id: string;

    appSpace: string;

    organization?: {
      _id: string;

      organization_id: string;

      organization_name?: string;

      name: string;

      country?: string;

      logo_public_url?: string;
    };

    logo?: {
      media_url: string;

      public_url: string;
    };

    smarttags?: Array<{
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    }>;

    name: string;

    displayName: string;

    detailUrl?: string;

    gender?: string;

    sport_sector: string;

    athletes?: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;
  }>;

  television_broadcasters?: Array<{
    _id: string;

    label: string;
  }>;

  internet_broadcasters?: Array<{
    _id: string;

    label: string;
  }>;

  startDate: string;

  sportGround?: {
    _id: string;

    name: string;

    sportGroundType: string;

    additionalData?: {};
  };

  competition: {
    _id: string;

    name: string;

    competitionGroupId: string;
  };

  competitionPart: {
    _id: string;

    name: string;
  };

  season: {
    _id: string;

    appSpace: string;

    name: string;

    dateFrom: string;

    dateTo: string;

    sport: string;
  };

  round?: {
    _id?: string;

    name: string;

    dateFrom?: string;

    dateTo?: string;
  };

  nominations: Array<{
    teamId?: string;

    athletes: Array<{
      sportnetUser: {
        _id: string;

        name: string;
      };

      additionalData?: {};
    }>;

    crew?: Array<{
      sportnetUser: {
        _id: string | null;

        name: string | null;
      };

      position: string;
    }>;

    closed?: boolean;
  }>;

  protocol?: {
    audience?: number;

    events?: Array<{}>;
  };

  rules?: {
    gender?: string;

    sport_sector?: string;

    discipline?: string;
  };

  closed?: boolean;

  contumation?: {
    isContumated: boolean;

    advantage: null | string;

    keepScore: boolean;
  };

  score?: Array<number>;

  livestream?: string;

  ticketsUrl?: string;

  detailUrl?: string;

  resultsTable?: {
    results: Array<{
      team: {
        _id: string;

        name: string;

        displayName: string;

        organization?: {
          _id: string;

          name: string;

          organization_id: string;

          organization_name: string;

          country?: string;
        };

        resignation?: boolean;
      };

      stats: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      homeStats?: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      awayStats?: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      matches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;

      homeMatches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;

      awayMatches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;
    }>;

    players?: Array<{
      _id: string;

      teamId: string;

      name: string;

      stats: {
        match_starts?: number;

        match_appearances?: number;

        goals?: number;

        assists?: number;

        yellow_cards?: number;

        red_cards?: number;

        substitutions?: number;

        own_goals?: number;
      };
    }>;
  };

  settings: {
    players: {
      field: {
        min: number;

        max: number;
      };

      substitutes: {
        max: number;
      };
    };

    contumation: {
      score: number;
    };

    reverseTime: boolean;

    resultsTableDisabled?: boolean;

    overlapTime: boolean;

    phases: {};
  };

  liveState?: {
    phase: string;

    seconds: number;

    ongoing: boolean;

    toggleDate: string;
  };

  drawId?: number;

  timer?: {};

  managers?: Array<{
    user: {
      _id: string;

      name: string;
    };

    type: {
      label: string;

      value: string;
    };

    roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
  }>;
};
export type Settings_Base_domain = string;
export type Settings = {
  domain?: string;

  homepageSectionIdOrUniqId?: string;
};
export type Settings_Edit = {
  domain?: string;

  homepageSectionIdOrUniqId?: string;
};
export type Pager = {
  limit: number;

  offset: number;

  total: number;

  nextOffset?: number;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 *
 * @class Api
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class Api {
  protected baseUrl: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      const responseContentType =
        (response.headers && response.headers.get('Content-Type')) || '';
      if (responseContentType.includes('application/json')) {
        return response.json();
      } else if (responseContentType.includes('application/pdf')) {
        return response.blob();
      } else if (responseContentType.includes('text/plain')) {
        return response.text();
      }
      return {};
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   * Get Contentspace Details
   * @method
   * @name Api#publicGetSettings
   */
  publicGetSettings(
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    domain?: string;

    homepageSectionIdOrUniqId?: string;
  }> {
    let path = '/public/{appSpace}/settings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getMatchResultsTable
   */
  getMatchResultsTable(
    matchId: string,
    parameters: {} = {},
  ): Promise<{
    results: Array<{
      team: {
        _id: string;

        name: string;

        displayName: string;

        organization?: {
          _id: string;

          name: string;

          organization_id: string;

          organization_name: string;

          country?: string;
        };

        resignation?: boolean;
      };

      stats: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      homeStats?: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      awayStats?: {
        matches: {
          played: number;

          won: number;

          draw: number;

          lost: number;
        };

        goals: {
          given: number;

          received: number;
        };

        points: number;
      };

      matches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;

      homeMatches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;

      awayMatches?: Array<{
        team: {
          _id: string;

          name: string;

          displayName: string;
        };

        result: 'W' | 'L' | 'D';

        homeaway: 'home' | 'away';

        matchId: string;

        score: string;
      }>;
    }>;
  }> {
    let path = '/matches/{matchId}/results';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getMatchVarEvents
   */
  getMatchVarEvents(
    matchId: string,
    parameters: {} = {},
  ): Promise<{
    varEvents: Array<{
      status: string;

      subject?: string;

      detailedSubject?: string;

      verdict?: string;

      datetime: string;

      _id: string;

      description: string;

      authUser: {
        sportnetId?: string;

        displayName?: string;
      };
    }>;
  }> {
    let path = '/matches/{matchId}/varevents';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getMatch
   */
  getMatch(
    matchId: string,
    parameters: {
      noCache?: number;
    } = {},
  ): Promise<{
    _id: string;

    appSpace: string;

    smarttags?: {
      key: string | number;

      values: Array<{
        key: string | number;

        title?: string | null;
      }>;
    };

    perex?: string;

    smarttagsByAppSpace?: Array<{
      appSpace: string;

      smarttags: {
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      };
    }>;

    teams: Array<{
      _id: string;

      appSpace: string;

      organization?: {
        _id: string;

        organization_id: string;

        organization_name?: string;

        name: string;

        country?: string;

        logo_public_url?: string;
      };

      logo?: {
        media_url: string;

        public_url: string;
      };

      smarttags?: Array<{
        key: string | number;

        values: Array<{
          key: string | number;

          title?: string | null;
        }>;
      }>;

      name: string;

      displayName: string;

      detailUrl?: string;

      gender?: string;

      sport_sector: string;

      athletes?: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;
    }>;

    television_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    internet_broadcasters?: Array<{
      _id: string;

      label: string;
    }>;

    startDate: string;

    sportGround?: {
      _id: string;

      name: string;

      sportGroundType: string;

      additionalData?: {};
    };

    competition: {
      _id: string;

      name: string;

      competitionGroupId: string;
    };

    competitionPart: {
      _id: string;

      name: string;
    };

    season: {
      _id: string;

      appSpace: string;

      name: string;

      dateFrom: string;

      dateTo: string;

      sport: string;
    };

    round?: {
      _id?: string;

      name: string;

      dateFrom?: string;

      dateTo?: string;
    };

    nominations: Array<{
      teamId?: string;

      athletes: Array<{
        sportnetUser: {
          _id: string;

          name: string;
        };

        additionalData?: {};
      }>;

      crew?: Array<{
        sportnetUser: {
          _id: string | null;

          name: string | null;
        };

        position: string;
      }>;

      closed?: boolean;
    }>;

    protocol?: {
      audience?: number;

      events?: Array<{}>;
    };

    rules?: {
      gender?: string;

      sport_sector?: string;

      discipline?: string;
    };

    closed?: boolean;

    contumation?: {
      isContumated: boolean;

      advantage: null | string;

      keepScore: boolean;
    };

    __issfId?: number;

    score?: Array<number>;

    livestream?: string;

    ticketsUrl?: string;

    detailUrl?: string;

    settings: {
      players: {
        field: {
          min: number;

          max: number;
        };

        substitutes: {
          max: number;
        };
      };

      contumation: {
        score: number;
      };

      reverseTime: boolean;

      resultsTableDisabled?: boolean;

      overlapTime: boolean;

      phases: {};
    };

    liveState?: {
      phase: string;

      seconds: number;

      ongoing: boolean;

      toggleDate: string;
    };

    drawId?: number;

    timer?: {};

    managers?: Array<{
      user: {
        _id: string;

        name: string;
      };

      type: {
        label: string;

        value: string;
      };

      roles?: Array<'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR'>;
    }>;
  }> {
    let path = '/matches/{matchId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['noCache'] !== 'undefined') {
      queryParameters['noCache'] = parameters['noCache'];
    }

    queryParameters['noCache'] = this.transformParameter(
      queryParameters['noCache'],
    );

    path = path.replace('{matchId}', matchId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getSettingById
   */
  getSettingById(settingId: string, parameters: {} = {}): Promise<{}> {
    let path = '/settings/{settingId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{settingId}', settingId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getSettingBySportSector
   */
  getSettingBySportSector(
    settingId: string,
    sectorId: string,
    parameters: {} = {},
  ): Promise<{
    items: Array<{
      _id: string;

      label: string;
    }>;
  }> {
    let path = '/settings/{settingId}/sector/{sectorId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{settingId}', settingId.toString());

    path = path.replace('{sectorId}', sectorId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getMatches
   */
  getMatches(
    parameters: {
      noCache?: number;
      playerAppSpace?: string;
      playerId?: string;
      competitionGroupId?: string;
      competitionId?: string;
      partId?: string;
      roundId?: string;
      dateFrom?: string;
      dateTo?: string;
      withDate?: boolean;
      withTeams?: boolean;
      closed?: boolean;
      teamId?: string;
      seasonName?: string;
      ids?: Array<string>;
      excludeIds?: Array<string>;
      teamAppSpaces?: Array<string>;
      teamIds?: Array<string>;
      allTeamIds?: Array<string>;
      appSpace?: string;
      smarttagsAppSpace?: string;
      smarttags?: Array<string>;
      rulesSportSector?: string;
      managerRoles?: 'VAR' | 'NOMINATIONS' | 'PROTOCOL' | 'DATACOLLECTOR';
      sorter?:
        | 'dateFromAsc'
        | 'dateFromDesc'
        | 'roundDateFromAsc'
        | 'roundDateFromDesc';
      offset?: number;
      limit?: number;
    } = {},
  ): Promise<{
    nextOffset?: number | null;

    total?: number;

    matches: Array<{
      _id: string;

      __issfId?: number;

      __externalId?: string;

      score?: Array<number>;

      drawId?: number;

      rules: {
        sport_sector: string;
      };

      appSpace: string;

      timer?: {};

      protocol?: {
        audience?: number;

        events?: Array<{}>;
      };

      teams: Array<{
        _id: string;

        appSpace: string;

        name: string;

        displayName: string;

        detailUrl?: string;

        gender?: string;

        sport_sector: string;

        additionalProperties?: {
          homeaway?: 'home' | 'away';
        };
      }>;

      startDate: string;

      sportGround?: {
        appSpace: string;

        city?: string;

        country?: string;

        image?: {
          public_url: string;

          media_url: string;
        };

        name: string;

        number?: string;

        perex?: string;

        sport?: string;

        sportGroundType?: string;

        sportObjectName: string;

        street?: string;

        zip?: string;

        _id: string;
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      settings?: {
        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};

        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      contumation?: {
        isContumated?: boolean;

        keepScore?: boolean;

        advantage?: string;
      };

      detailUrl?: string;
    }>;
  }> {
    let path = '/matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['noCache'] !== 'undefined') {
      queryParameters['noCache'] = parameters['noCache'];
    }

    queryParameters['noCache'] = this.transformParameter(
      queryParameters['noCache'],
    );

    if (typeof parameters['playerAppSpace'] !== 'undefined') {
      queryParameters['playerAppSpace'] = parameters['playerAppSpace'];
    }

    queryParameters['playerAppSpace'] = this.transformParameter(
      queryParameters['playerAppSpace'],
    );

    if (typeof parameters['playerId'] !== 'undefined') {
      queryParameters['playerId'] = parameters['playerId'];
    }

    queryParameters['playerId'] = this.transformParameter(
      queryParameters['playerId'],
    );

    if (typeof parameters['competitionGroupId'] !== 'undefined') {
      queryParameters['competitionGroupId'] = parameters['competitionGroupId'];
    }

    queryParameters['competitionGroupId'] = this.transformParameter(
      queryParameters['competitionGroupId'],
    );

    if (typeof parameters['competitionId'] !== 'undefined') {
      queryParameters['competitionId'] = parameters['competitionId'];
    }

    queryParameters['competitionId'] = this.transformParameter(
      queryParameters['competitionId'],
    );

    if (typeof parameters['partId'] !== 'undefined') {
      queryParameters['partId'] = parameters['partId'];
    }

    queryParameters['partId'] = this.transformParameter(
      queryParameters['partId'],
    );

    if (typeof parameters['roundId'] !== 'undefined') {
      queryParameters['roundId'] = parameters['roundId'];
    }

    queryParameters['roundId'] = this.transformParameter(
      queryParameters['roundId'],
    );

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    if (typeof parameters['withDate'] !== 'undefined') {
      queryParameters['withDate'] = parameters['withDate'];
    }

    queryParameters['withDate'] = this.transformParameter(
      queryParameters['withDate'],
    );

    if (typeof parameters['withTeams'] !== 'undefined') {
      queryParameters['withTeams'] = parameters['withTeams'];
    }

    queryParameters['withTeams'] = this.transformParameter(
      queryParameters['withTeams'],
    );

    if (typeof parameters['closed'] !== 'undefined') {
      queryParameters['closed'] = parameters['closed'];
    }

    queryParameters['closed'] = this.transformParameter(
      queryParameters['closed'],
    );

    if (typeof parameters['teamId'] !== 'undefined') {
      queryParameters['teamId'] = parameters['teamId'];
    }

    queryParameters['teamId'] = this.transformParameter(
      queryParameters['teamId'],
    );

    if (typeof parameters['seasonName'] !== 'undefined') {
      queryParameters['seasonName'] = parameters['seasonName'];
    }

    queryParameters['seasonName'] = this.transformParameter(
      queryParameters['seasonName'],
    );

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    if (typeof parameters['excludeIds'] !== 'undefined') {
      queryParameters['excludeIds'] = parameters['excludeIds'];
    }

    queryParameters['excludeIds'] = this.transformParameter(
      queryParameters['excludeIds'],
      'joinUsingPipes',
    );

    if (typeof parameters['teamAppSpaces'] !== 'undefined') {
      queryParameters['teamAppSpaces'] = parameters['teamAppSpaces'];
    }

    queryParameters['teamAppSpaces'] = this.transformParameter(
      queryParameters['teamAppSpaces'],
      'joinUsingPipes',
    );

    if (typeof parameters['teamIds'] !== 'undefined') {
      queryParameters['teamIds'] = parameters['teamIds'];
    }

    queryParameters['teamIds'] = this.transformParameter(
      queryParameters['teamIds'],
      'joinUsingPipes',
    );

    if (typeof parameters['allTeamIds'] !== 'undefined') {
      queryParameters['allTeamIds'] = parameters['allTeamIds'];
    }

    queryParameters['allTeamIds'] = this.transformParameter(
      queryParameters['allTeamIds'],
      'joinUsingPipes',
    );

    if (typeof parameters['appSpace'] !== 'undefined') {
      queryParameters['appSpace'] = parameters['appSpace'];
    }

    queryParameters['appSpace'] = this.transformParameter(
      queryParameters['appSpace'],
    );

    if (typeof parameters['smarttagsAppSpace'] !== 'undefined') {
      queryParameters['smarttagsAppSpace'] = parameters['smarttagsAppSpace'];
    }

    queryParameters['smarttagsAppSpace'] = this.transformParameter(
      queryParameters['smarttagsAppSpace'],
    );

    if (typeof parameters['smarttags'] !== 'undefined') {
      queryParameters['smarttags'] = parameters['smarttags'];
    }

    queryParameters['smarttags'] = this.transformParameter(
      queryParameters['smarttags'],
      'joinUsingPipes',
    );

    if (typeof parameters['rulesSportSector'] !== 'undefined') {
      queryParameters['rulesSportSector'] = parameters['rulesSportSector'];
    }

    queryParameters['rulesSportSector'] = this.transformParameter(
      queryParameters['rulesSportSector'],
    );

    if (typeof parameters['managerRoles'] !== 'undefined') {
      queryParameters['managerRoles'] = parameters['managerRoles'];
    }

    queryParameters['managerRoles'] = this.transformParameter(
      queryParameters['managerRoles'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 20;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getH2HMatches
   */
  getH2HMatches(
    parameters: {
      competitionGroupId?: string;
      dateFrom?: string;
      dateTo?: string;
      closed?: boolean;
      excludeIds?: Array<string>;
      teamAppSpaces?: Array<string>;
      limit?: number;
    } = {},
  ): Promise<{
    firstTeamMatches?: Array<{
      _id: string;

      __issfId?: number;

      score?: Array<number>;

      drawId?: number;

      rules: {
        sport_sector: string;
      };

      appSpace: string;

      timer?: {};

      protocol?: {
        audience?: number;

        events?: Array<{}>;
      };

      teams: Array<{
        _id: string;

        appSpace: string;

        name: string;

        displayName: string;

        gender?: string;

        sport_sector: string;

        additionalProperties?: {
          homeaway?: 'home' | 'away';
        };
      }>;

      startDate: string;

      sportGround?: {
        appSpace: string;

        city?: string;

        country?: string;

        image?: {
          public_url: string;

          media_url: string;
        };

        name: string;

        number?: string;

        perex?: string;

        sport?: string;

        sportGroundType?: string;

        sportObjectName: string;

        street?: string;

        zip?: string;

        _id: string;
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      settings?: {
        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};

        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      contumation?: {
        isContumated?: boolean;

        keepScore?: boolean;

        advantage?: string;
      };
    }>;

    secondTeamMatches?: Array<{
      _id: string;

      __issfId?: number;

      score?: Array<number>;

      drawId?: number;

      rules: {
        sport_sector: string;
      };

      appSpace: string;

      timer?: {};

      protocol?: {
        audience?: number;

        events?: Array<{}>;
      };

      teams: Array<{
        _id: string;

        appSpace: string;

        name: string;

        displayName: string;

        gender?: string;

        sport_sector: string;

        additionalProperties?: {
          homeaway?: 'home' | 'away';
        };
      }>;

      startDate: string;

      sportGround?: {
        appSpace: string;

        city?: string;

        country?: string;

        image?: {
          public_url: string;

          media_url: string;
        };

        name: string;

        number?: string;

        perex?: string;

        sport?: string;

        sportGroundType?: string;

        sportObjectName: string;

        street?: string;

        zip?: string;

        _id: string;
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      settings?: {
        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};

        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      contumation?: {
        isContumated?: boolean;

        keepScore?: boolean;

        advantage?: string;
      };
    }>;

    commonMatches?: Array<{
      _id: string;

      __issfId?: number;

      score?: Array<number>;

      drawId?: number;

      rules: {
        sport_sector: string;
      };

      appSpace: string;

      timer?: {};

      protocol?: {
        audience?: number;

        events?: Array<{}>;
      };

      teams: Array<{
        _id: string;

        appSpace: string;

        name: string;

        displayName: string;

        gender?: string;

        sport_sector: string;

        additionalProperties?: {
          homeaway?: 'home' | 'away';
        };
      }>;

      startDate: string;

      sportGround?: {
        appSpace: string;

        city?: string;

        country?: string;

        image?: {
          public_url: string;

          media_url: string;
        };

        name: string;

        number?: string;

        perex?: string;

        sport?: string;

        sportGroundType?: string;

        sportObjectName: string;

        street?: string;

        zip?: string;

        _id: string;
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      settings?: {
        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};

        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      contumation?: {
        isContumated?: boolean;

        keepScore?: boolean;

        advantage?: string;
      };
    }>;
  }> {
    let path = '/h2h-matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['competitionGroupId'] !== 'undefined') {
      queryParameters['competitionGroupId'] = parameters['competitionGroupId'];
    }

    queryParameters['competitionGroupId'] = this.transformParameter(
      queryParameters['competitionGroupId'],
    );

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    if (typeof parameters['closed'] !== 'undefined') {
      queryParameters['closed'] = parameters['closed'];
    }

    queryParameters['closed'] = this.transformParameter(
      queryParameters['closed'],
    );

    if (typeof parameters['excludeIds'] !== 'undefined') {
      queryParameters['excludeIds'] = parameters['excludeIds'];
    }

    queryParameters['excludeIds'] = this.transformParameter(
      queryParameters['excludeIds'],
      'joinUsingPipes',
    );

    if (typeof parameters['teamAppSpaces'] !== 'undefined') {
      queryParameters['teamAppSpaces'] = parameters['teamAppSpaces'];
    }

    queryParameters['teamAppSpaces'] = this.transformParameter(
      queryParameters['teamAppSpaces'],
      'joinUsingPipes',
    );

    queryParameters['limit'] = 20;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getLastRoundMatches
   */
  getLastRoundMatches(
    competitionId: string,
    parameters: {
      offset?: number;
      limit?: number;
    } = {},
  ): Promise<{
    nextOffset?: number | null;

    total?: number;

    matches: Array<{
      _id: string;

      __issfId?: number;

      drawId?: number;

      rules: {
        sport_sector: string;
      };

      appSpace: string;

      timer?: {};

      protocol?: {
        audience?: number;

        events?: Array<{}>;
      };

      teams: Array<{
        _id: string;

        appSpace: string;

        name: string;

        displayName: string;

        gender?: string;

        sport_sector: string;

        additionalProperties?: {
          homeaway?: 'home' | 'away';
        };
      }>;

      startDate: string;

      sportGround?: {
        appSpace: string;

        city?: string;

        country?: string;

        image?: {
          public_url: string;

          media_url: string;
        };

        name: string;

        number?: string;

        perex?: string;

        sport?: string;

        sportGroundType?: string;

        sportObjectName: string;

        street?: string;

        zip?: string;

        _id: string;
      };

      competition: {
        _id: string;

        name: string;

        competitionGroupId: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      settings?: {
        contumation: {
          score: number;
        };

        reverseTime: boolean;

        resultsTableDisabled?: boolean;

        overlapTime: boolean;

        phases: {};

        players: {
          field: {
            min: number;

            max: number;
          };

          substitutes: {
            max: number;
          };
        };
      };

      round?: {
        _id: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      contumation?: {
        isContumated?: boolean;

        keepScore?: boolean;

        advantage?: string;
      };
    }>;
  }> {
    let path = '/public/competitions/{competitionId}/last-round-matches';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name Api#getPlayerMatches
   */
  getPlayerMatches(
    appSpace: string,
    sportnetId: string,
    parameters: {
      limit?: number;
      offset?: number;
      competitionId?: string;
      competitionGroupId?: string;
      partId?: string;
      roundId?: string;
      dateFrom?: string;
      dateTo?: string;
      closed?: boolean;
      teamId?: string;
      ids?: Array<string>;
      sorter?:
        | 'dateFromAsc'
        | 'dateFromDesc'
        | 'roundDateFromAsc'
        | 'roundDateFromDesc';
    } = {},
  ): Promise<{
    limit: number;

    offset: number;

    total: number;

    nextOffset?: number;

    matches: Array<{
      _id: string;

      __externalId?: string;

      drawId?: number;

      rules: {
        sport_sector: string;
      };

      appSpace: string;

      timer?: {};

      protocol?: {
        audience?: number;

        events?: Array<{}>;
      };

      teams: Array<{
        _id: string;

        appSpace: string;

        name: string;

        displayName: string;

        gender?: string;

        sport_sector: string;

        additionalProperties?: {
          homeaway?: 'home' | 'away';
        };
      }>;

      startDate: string;

      sportGround?: {
        appSpace: string;

        city?: string;

        country?: string;

        image?: {
          public_url: string;

          media_url: string;
        };

        name: string;

        number?: string;

        perex?: string;

        sport?: string;

        sportGroundType?: string;

        sportObjectName: string;

        street?: string;

        zip?: string;

        _id: string;
      };

      competition: {
        _id: string;

        name: string;
      };

      competitionPart: {
        _id: string;

        name: string;
      };

      season: {
        _id: string;

        appSpace: string;

        name: string;

        dateFrom: string;

        dateTo: string;

        sport: string;
      };

      round?: {
        _id?: string;

        name: string;

        dateFrom?: string;

        dateTo?: string;
      };

      closed?: boolean;

      detailUrl?: string;
    }>;
  }> {
    let path = '/player-matches/ppo/{appSpace}/player/{sportnetId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{sportnetId}', sportnetId.toString());

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['competitionId'] !== 'undefined') {
      queryParameters['competitionId'] = parameters['competitionId'];
    }

    queryParameters['competitionId'] = this.transformParameter(
      queryParameters['competitionId'],
    );

    if (typeof parameters['competitionGroupId'] !== 'undefined') {
      queryParameters['competitionGroupId'] = parameters['competitionGroupId'];
    }

    queryParameters['competitionGroupId'] = this.transformParameter(
      queryParameters['competitionGroupId'],
    );

    if (typeof parameters['partId'] !== 'undefined') {
      queryParameters['partId'] = parameters['partId'];
    }

    queryParameters['partId'] = this.transformParameter(
      queryParameters['partId'],
    );

    if (typeof parameters['roundId'] !== 'undefined') {
      queryParameters['roundId'] = parameters['roundId'];
    }

    queryParameters['roundId'] = this.transformParameter(
      queryParameters['roundId'],
    );

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    if (typeof parameters['closed'] !== 'undefined') {
      queryParameters['closed'] = parameters['closed'];
    }

    queryParameters['closed'] = this.transformParameter(
      queryParameters['closed'],
    );

    if (typeof parameters['teamId'] !== 'undefined') {
      queryParameters['teamId'] = parameters['teamId'];
    }

    queryParameters['teamId'] = this.transformParameter(
      queryParameters['teamId'],
    );

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new Api();
export { Api };
