import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import MatchDetailPage from 'pages/MatchDetailPage';
import MatchesListPage from 'pages/MatchesListPage';
import NotFoundPage from 'pages/NotFoundPage';

const Routes: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/matches" />
        </Route>
        <Route exact path="/matches" component={MatchesListPage} />
        <Route exact path="/match/:matchId" component={MatchDetailPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default Routes;
