import { useQuery } from 'react-query';
import CompetitionsApi from 'api/CompetitionsApi';

export default function useMatchVarEvents(matchId: string) {
  return useQuery(
    ['match-var-events', matchId],
    () => {
      return CompetitionsApi.getMatchVarEvents(matchId);
    },
    {
      enabled: !!matchId,
      refetchInterval: 10 * 1000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: 'always',
    }
  );
}
