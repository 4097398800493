import { rem } from 'polished';
import HeaderBar from '@sportnet/ui/lib/HeaderBar';
import React from 'react';
import StatusBar from '@sportnet/ui/lib/TheLayout/StatusBar';
import __ from 'utilities/getText';
import appContext from 'context/appContenxt';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const Body = styled.div`
  position: relative;
  overflow-y: auto;
  flex: 1 0 0;
  height: 100%;
  background-color: #f4f5f9;
  max-width: ${rem(1920)};
  display: block;
`;

export const Layout: React.FC = ({ children }) => {
  const [soundOn, setSoundOn] = React.useState(false);
  const [soundNotification, setSoundNotification] = React.useState<
    HTMLAudioElement | undefined
  >(undefined);

  React.useEffect(() => {
    const notif = new Audio('/Air-Plane-Ding-SoundBible.com-496729130.mp3');

    const handleCanPlayThrough = () => {
      notif.muted = true;
      const promise = notif.play();
      if (promise !== undefined) {
        promise
          .catch(() => {
            notif.muted = false;
            setSoundNotification(notif);
          })
          .then(() => {
            notif.muted = false;
            setSoundNotification(notif);
          });
      }
    };

    notif.addEventListener('canplaythrough', handleCanPlayThrough);

    return () => {
      notif.removeEventListener('canplaythrough', handleCanPlayThrough);
    };
  }, []);

  return (
    <appContext.Provider
      value={{
        soundOn,
        toggleSoundOn: () => {
          setSoundOn(!soundOn);
        },
        soundNotification,
      }}
    >
      <Wrapper>
        <StatusBar>
          <StatusBar.Item>
            <a href="/" style={{ display: 'flex' }}>
              <img src="/logo-sfz.png" width="48" alt="Logo SFZ" />
            </a>
          </StatusBar.Item>
          <StatusBar.Item>
            <HeaderBar.Header subtitle={'Prehľad VAR udalostí k zápasom.'}>
              {__('Videoasistent rozhodcu')}
            </HeaderBar.Header>
          </StatusBar.Item>
          <StatusBar.Spacer />
        </StatusBar>
        <Body>{children}</Body>
      </Wrapper>
    </appContext.Provider>
  );
};

export default Layout;
