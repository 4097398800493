import { useInfiniteQuery } from 'react-query';
import CompetitionsApi from 'api/CompetitionsApi';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';

export default function useMatchesListForDay(day: Date | null) {
  const dateFrom = day ? startOfDay(day).toJSON() : '';
  const dateTo = day ? endOfDay(day).toJSON() : '';

  return useInfiniteQuery(
    ['matches-list', dateFrom, dateFrom],
    ({ pageParam }) => {
      return CompetitionsApi.getMatches({
        rulesSportSector: 'futbal',
        managerRoles: 'VAR',
        dateFrom,
        dateTo,
        limit: 100,
        offset: pageParam ?? 0,
        noCache: 1,
      });
    },
    {
      enabled: !!day,
      getNextPageParam: (lastPage) => lastPage.nextOffset,
    }
  );
}
