import { Link } from 'react-router-dom';
import { rem } from 'polished';
import HeaderBar from '@sportnet/ui/lib/HeaderBar';
import React from 'react';
import ScrollLayout from '@sportnet/ui/lib/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/lib/Segment';
import styled from 'styled-components';

const MainSegment = styled(Segment)`
  margin: ${rem(8)};
`;

interface IOwnProps {
  title?: string;
  linkBack?: string;
  children?: React.ReactNode;
  restHeaderbarElements?: React.ReactNode;
}

const PageContent: React.FC<IOwnProps> = ({
  title,
  linkBack,
  restHeaderbarElements,
  children,
}) => {
  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          {linkBack && <HeaderBar.Action icon="back" as={Link} to={linkBack} />}
          {title && <HeaderBar.Header>{title}</HeaderBar.Header>}
          {restHeaderbarElements}
        </HeaderBar>
      }
    >
      <MainSegment raised>{children}</MainSegment>
    </ScrollLayout>
  );
};

export default React.memo(PageContent);
