import HeaderBar from '@sportnet/ui/lib/HeaderBar';
import Message from '@sportnet/ui/lib/Message';
import ScrollLayout from '@sportnet/ui/lib/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/lib/Segment';
import __ from 'utilities/getText';

export const NotFoundPage: React.FC = () => {
  return (
    <ScrollLayout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__('Error')}</HeaderBar.Header>
          <HeaderBar.Spacer />
        </HeaderBar>
      }
    >
      <Segment noBottomGutter>
        <Message danger title="Page not found!">
          Page You are looking for was not found.
        </Message>
      </Segment>
    </ScrollLayout>
  );
};

export default NotFoundPage;
