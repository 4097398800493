import * as React from 'react';
import {
  FALLBACK_LOGO_IMAGE,
  cleanRoundName,
  getTeamLogoSrc,
  getTeamName,
} from '@sportnet/content/lib/view/widgets/MatchesList/utilities';
import { ITheme } from '@sportnet/content/lib/theme/theme';
import { IWidgetMatchesListMatchItem } from '@sportnet/content/lib/library/Competitions';
import { Link } from 'react-router-dom';
import { format, isAfter, isBefore, isEqual, startOfDay } from 'date-fns';
import { getProp } from 'sportnet-utilities';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import { withTheme } from '@sportnet/content/lib/theme/styled-components';
import ContentContext from '@sportnet/content/lib/common/ContentContext';
import Icon from '@sportnet/content/lib/view/components/Icon';
import Image from '@sportnet/ui/lib/Image';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const MatchWrapperLink = styled(Link)`
  text-decoration: none;
  color: #333;
  &.live {
    color: ${({ theme }) => theme.color.danger};
  }
`;

const MatchWrapperDiv = styled.div<{ isLive: boolean }>`
  color: ${({ isLive, theme }) => (isLive ? theme.color.danger : '#333')};
`;

const MatchDetailLink = ({
  href,
  isLive,
  children,
}: {
  href: string;
  isLive: boolean;
  children: any;
}) => {
  if (href) {
    return (
      <MatchWrapperLink to={href} className={isLive ? 'live' : ''}>
        {children}
      </MatchWrapperLink>
    );
  }
  return <MatchWrapperDiv isLive={isLive}>{children}</MatchWrapperDiv>;
};

const CompactMatchDetailLink = ({
  href,
  isLive,
  children,
}: {
  href: string;
  isLive: boolean;
  children: any;
}) => {
  if (href) {
    return (
      <MatchWrapperLink to={href} className={isLive ? 'live' : ''}>
        {children}
      </MatchWrapperLink>
    );
  }
  return <MatchWrapperDiv isLive={isLive}>{children}</MatchWrapperDiv>;
};

const MatchStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${({ theme }) => theme.color.fadedText};
  width: 100%;
  font-size: ${rem(12)};
`;
const DateInfo = styled.div``;
const MatchStatusInfo = styled.div``;
const LiveIndicator = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.danger};
  :before {
    content: '';
    width: ${rem(8)};
    height: ${rem(8)};
    background: ${({ theme }) => theme.color.danger};
    display: inline-block;
    border-radius: 100%;
    margin-right: ${rem(8)};
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
`;

const StrechWidthScore = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 70%;
  > div {
    width: 35%;
    padding: 0 ${({ theme }) => rem(theme.grid.gutterWidth / 2)};
  }
`;

const ScoreLine = styled.div`
  width: 30% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    width: 33.33%;
  }
`;

const Label = styled.div<{ center?: boolean }>`
  color: ${({ theme }) => theme.color.fadedText};
  width: 15%;
  ${({ center }) =>
    !!center &&
    css`
      ${LiveIndicator} {
        justify-content: center;
      }
    `}
`;

const FullWidthScore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.app.textColor};
`;

const FullWidthScoreLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${rem(16)};
  gap: ${rem(16)};
  flex-grow: 1;
  max-width: ${rem(804)};
`;

const FullWidthScoreLineTeam = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const FullWidthScoreItems = styled.div`
  text-align: center;
  font-size: ${rem(30)};
  ${mb('s')} {
    font-size: ${rem(40)};
  }
  font-weight: bold;
  ${Label} {
    font-size: ${rem(11)};
    color: ${({ theme }) => theme.app.textColor};
    text-transform: uppercase;
    padding-top: ${rem(4)};
    ${mb('s')} {
      font-size: ${rem(12)};
      padding-top: ${rem(8)};
    }
    font-weight: normal;
    width: 100%;
  }
`;

const FullWidthScoreLineTeamName = styled.div`
  width: 100%;
  padding-top: ${rem(8)};
  font-size: ${rem(11)};
  font-weight: bold;
  text-transform: uppercase;
  ${mb('s')} {
    font-size: ${rem(20)};
    font-weight: 600;
  }
`;

const ScoreTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: ${rem(12)};
  padding-top: ${rem(12)};
`;
const ScoreRow = styled.div<{ highlighted: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${rem(4)} 0;
  font-weight: ${({ highlighted }) => (highlighted ? '600' : '500')};
`;
const Team = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
const TeamLogo = styled.div`
  width: ${rem(40)};
  display: flex;
  justify-content: center;
  padding-right: ${rem(8)};
  img {
    max-height: ${rem(24)};
  }
`;

const StrechWidthTeamLogo = styled.div`
  display: flex;
  justify-content: center;
  img {
    max-height: ${rem(24)};
  }
`;

const FullWidthTeamLogo = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: ${rem(56)};
  height: ${rem(56)};
  ${mb('s')} {
    width: ${rem(72)};
    height: ${rem(72)};
  }
  background: white;
  border-radius: 50%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  img {
    // width: 100%;
    padding: ${rem(8)};
    // border-radius: 50%;
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const TeamName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
`;

const StrechWidthTeamName = styled.div`
  text-overflow: ellipsis;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
`;

const StrechResignation = styled.div`
  color: ${({ theme }) => theme.color.danger};
  font-size: ${rem(11)};
`;

const StrechWidthTeam = styled.div<{ highlighted: boolean; right?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${({ highlighted }) => (highlighted ? '600' : '500')};
  ${({ right }) =>
    right &&
    css`
      ${StrechWidthTeamName} {
        text-align: left;
      }
    `}
`;

const Score = styled.div<{ isLive: boolean }>`
  padding-left: ${rem(8)};
  display: flex;
  width: ${rem(40)};
  align-items: center;
  color: ${({ isLive, theme }) => (isLive ? theme.color.danger : 'inherit')};
`;

const ScoreItem = styled.span<{ highlighted: boolean }>`
  font-weight: ${({ highlighted }) => (highlighted ? '600' : '500')};
`;

const ScoreIcon = styled.div`
  display: flex;
  width: ${rem(24)};
  padding-left: ${rem(8)};
`;

const WinnerIconWrapper = styled.div<{ align: 'left' | 'right' }>`
  width: ${rem(32)};
  display: flex;
  justify-content: flex-end;
  ${({ align }) =>
    align === 'right' &&
    css`
      transform: rotate(180deg);
    `}
`;

const CompetitionName = styled.div`
  font-size: ${rem(12)};
  line-height: ${rem(14.4)};
  font-weight: 400;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: pre-wrap;
`;

const MiniScore = styled.div`
  font-size: ${rem(12)};
  line-height: ${rem(14.4)};
  font-weight: 400;
  color: ${({ theme }) => theme.color.fadedText};
`;

const PenaltyScore = styled.div`
  font-size: ${rem(12)};
  margin-top: ${rem(8)};
`;

interface IOwnProps {
  match: IWidgetMatchesListMatchItem;
  view?: 'compact' | 'streched' | 'full';
  theme: ITheme;
  showCompetitionName?: boolean;
  phases?: {
    [key: string]: Readonly<any>;
  };
  detailUrl?: string;
}

export type Props = IOwnProps & { theme: ITheme };

// const useForceUpdate = () => React.useState()[1];

const MatchPreview: React.FC<Props> = ({
  match,
  theme,
  view = 'compact',
  showCompetitionName,
  phases,
  detailUrl = '',
}) => {
  const context = React.useContext(ContentContext);
  const getTeamNameFunc = getProp(
    context,
    ['helpers', 'getTeamName'],
    getTeamName
  );
  const getTeamLogoSrcFunc = getProp(
    context,
    ['helpers', 'getTeamLogoSrc'],
    getTeamLogoSrc
  );
  // const forceUpdate = useForceUpdate();
  const [live, setLive] = React.useState<{
    timer: any;
    liveState: {
      ongoing: boolean;
      seconds: number;
      phase: string;
      toggleDate: string;
    };
    protocol: {
      events: any[];
    };
  }>({
    timer: {},
    liveState: {
      ongoing: false,
      seconds: 0,
      phase: '',
      toggleDate: '',
    },
    protocol: {
      events: [],
    },
  });

  const liveRef = React.useRef<any>();

  liveRef.current = live;

  React.useEffect(() => {
    if (
      match &&
      !match.closed &&
      isEqual(startOfDay(new Date()), startOfDay(new Date(match.startDate)))
    ) {
      setLive({
        liveState: match.liveState || live.liveState,
        timer: match.timer || live.timer,
        protocol: {
          ...(match.protocol || {}),
          events: getProp(match.protocol, ['events'], []),
        },
      });
    }
  }, [match, live.liveState, live.timer]);

  const getTeam = (teamSide: string) =>
    (match.teams || []).find(
      (i) => getProp(i, ['additionalProperties', 'homeaway'], '') === teamSide
    );

  const getTeamsIds = () => {
    const homeTeam = getTeam('home');
    const homeTeamId = homeTeam
      ? homeTeam._id
      : getProp(match, ['teams', 0, '_id']);
    const awayTeam = getTeam('away');
    const awayTeamId = awayTeam
      ? awayTeam._id
      : getProp(match, ['teams', 1, '_id']);

    return { homeTeamId, awayTeamId };
  };

  const getGoalsByTeams = () => {
    const { homeTeamId, awayTeamId } = getTeamsIds();
    let homeTeamGoals = 0;
    let awayTeamGoals = 0;
    if (match.contumationScore && match.contumationScore.length) {
      homeTeamGoals = match.contumationScore[0];
      awayTeamGoals = match.contumationScore[1];
    } else if (match.score && match.score.length) {
      homeTeamGoals = match.score[0];
      awayTeamGoals = match.score[1];
    } else if (getProp(live, ['protocol', 'events'], []).length) {
      homeTeamGoals = (live.protocol.events || []).reduce(
        (
          acc,
          e: { team: string; eventType: string; type: string; phase: string }
        ) => {
          if (
            (e.team === homeTeamId &&
              e.eventType === 'goal' &&
              e.type !== 'dropped' &&
              e.phase !== 'shootout') ||
            (e.team === awayTeamId &&
              e.eventType === 'goal' &&
              e.type === 'dropped')
          ) {
            return (acc || 0) + 1;
          }
          return acc;
        },
        homeTeamGoals
      );
      awayTeamGoals = (live.protocol.events || []).reduce(
        (
          acc,
          e: { team: string; eventType: string; type: string; phase: string }
        ) => {
          if (
            (e.team === awayTeamId &&
              e.eventType === 'goal' &&
              e.type !== 'dropped' &&
              e.phase !== 'shootout') ||
            (e.team === homeTeamId &&
              e.eventType === 'goal' &&
              e.type === 'dropped')
          ) {
            return (acc || 0) + 1;
          }
          return acc;
        },
        awayTeamGoals
      );
    } else if (match.protocol) {
      homeTeamGoals = getProp(match, ['score'], [0, 0])[0];
      awayTeamGoals = getProp(match, ['score'], [0, 0])[1];
    }
    return { homeTeamGoals, awayTeamGoals };
  };

  const getShootoutGoals = () => {
    const { homeTeamId, awayTeamId } = getTeamsIds();
    let homeTeamGoals = 0;
    let awayTeamGoals = 0;
    if (getProp(live, ['protocol', 'events'], []).length) {
      homeTeamGoals = (live.protocol.events || []).reduce(
        (
          acc,
          e: { team: string; eventType: string; type: string; phase: string }
        ) => {
          if (
            e.team === homeTeamId &&
            e.eventType === 'goal' &&
            e.phase === 'shootout'
          ) {
            return acc + 1;
          }
          return acc;
        },
        homeTeamGoals
      );
      awayTeamGoals = (live.protocol.events || []).reduce(
        (
          acc,
          e: { team: string; eventType: string; type: string; phase: string }
        ) => {
          if (
            e.team === awayTeamId &&
            e.eventType === 'goal' &&
            e.phase === 'shootout'
          ) {
            return acc + 1;
          }
          return acc;
        },
        awayTeamGoals
      );
    } else if (match.protocol && match.protocol.events) {
      homeTeamGoals = getProp(match, ['penaltiesScore'], [0, 0])[0];
      awayTeamGoals = getProp(match, ['penaltiesScore'], [0, 0])[1];
    }
    return { homeTeamGoals, awayTeamGoals };
  };

  // vrati skore prveho polcasu
  const getFirstHalfTimeScore = () => {
    if (!match.scoreByPhases) {
      return null;
    }
    return (
      <MiniScore>
        (
        {match.scoreByPhases
          .map((phaseScore) => phaseScore.join(':'))
          .join(', ')}
        )
      </MiniScore>
    );
  };

  const renderMatchStatus = () => {
    if (match.contumation && match.contumation.isContumated) {
      return 'Kontumované';
    } else if (match.closed || live.liveState.phase === 'end') {
      return 'Koniec';
    } else if (
      !match.closed &&
      ((match.startDate && isAfter(new Date(), new Date(match.startDate))) ||
        (match.liveState && live.liveState.phase))
    ) {
      return <LiveIndicator>Live</LiveIndicator>;
    } else if (!match.closed) {
      return '';
    } else {
      return <LiveIndicator>Live</LiveIndicator>;
    }
  };

  const renderCompetitionName = () => {
    const compName = match.competition.name || '';
    const roundName = match.round?.name || '';
    const formattedRoundName = roundName
      ? ' - ' + cleanRoundName(roundName)
      : '';
    return (
      <CompetitionName>
        <span>{compName}</span>
        {formattedRoundName && <span>{formattedRoundName}</span>}
      </CompetitionName>
    );
  };

  const isFutureMatch = () =>
    !match.closed &&
    (!match.startDate ||
      (match.startDate && isBefore(new Date(), new Date(match.startDate)))) &&
    !match.liveState &&
    !live.liveState.phase;

  const isLive = () =>
    (match.startDate &&
      isAfter(new Date(), new Date(match.startDate)) &&
      !match.closed) ||
    (live.liveState.phase && live.liveState.phase !== 'end' && !match.closed);

  const renderTeamsScores = () => {
    const homeTeam = getTeam('home');
    const awayTeam = getTeam('away');
    const { homeTeamGoals, awayTeamGoals } = getGoalsByTeams();

    const homeTeamOrganization =
      homeTeam?.organization || match?.teams?.[0]?.organization;
    const homeTeamLogoSrc =
      homeTeamOrganization && homeTeamOrganization._id
        ? getTeamLogoSrcFunc(match.appSpace, homeTeam)
        : FALLBACK_LOGO_IMAGE;

    const awayTeamOrganization =
      awayTeam?.organization || match?.teams?.[1]?.organization;
    const awayTeamLogoSrc =
      awayTeamOrganization && awayTeamOrganization._id
        ? getTeamLogoSrcFunc(match.appSpace, awayTeam)
        : FALLBACK_LOGO_IMAGE;

    return (
      <>
        <ScoreRow
          highlighted={!!homeTeamGoals && homeTeamGoals > awayTeamGoals}
        >
          <Team>
            <TeamLogo>
              <Image
                alt={getProp(
                  homeTeam,
                  ['organization', 'name'],
                  getProp(match, ['teams', 0, 'organization', 'name'])
                )}
                src={homeTeamLogoSrc}
                fallbackSrc={FALLBACK_LOGO_IMAGE}
              />
            </TeamLogo>
            <TeamName>
              {getProp(
                homeTeam,
                ['organization', 'name'],
                getProp(match, ['teams', 0, 'organization', 'name'])
              )}
            </TeamName>
          </Team>
          <Score isLive={!!isLive()}>
            {!isFutureMatch() && (
              <>
                <div>{homeTeamGoals}</div>
                <ScoreIcon>
                  {!!(homeTeamGoals && homeTeamGoals > awayTeamGoals) && (
                    <Icon
                      size="xs"
                      name="arrowWinner"
                      color={
                        isLive() ? theme.color.danger : theme.app.textColor
                      }
                    />
                  )}
                </ScoreIcon>
              </>
            )}
          </Score>
        </ScoreRow>
        <ScoreRow
          highlighted={!!awayTeamGoals && awayTeamGoals > homeTeamGoals}
        >
          <Team>
            <TeamLogo>
              <Image
                fallbackSrc={FALLBACK_LOGO_IMAGE}
                alt={getProp(
                  awayTeam,
                  ['organization', 'name'],
                  getProp(match, ['teams', 1, 'organization', 'name'])
                )}
                src={awayTeamLogoSrc}
              />
            </TeamLogo>
            <TeamName>
              {getProp(
                awayTeam,
                ['organization', 'name'],
                getProp(match, ['teams', 1, 'organization', 'name'])
              )}
            </TeamName>
          </Team>
          <Score isLive={!!isLive()}>
            {!isFutureMatch() && (
              <>
                <div>{awayTeamGoals}</div>
                <ScoreIcon>
                  {!!(awayTeamGoals && awayTeamGoals > homeTeamGoals) && (
                    <Icon
                      size="xs"
                      name="arrowWinner"
                      color={
                        isLive() ? theme.color.danger : theme.app.textColor
                      }
                    />
                  )}
                </ScoreIcon>
              </>
            )}
          </Score>
        </ScoreRow>
      </>
    );
  };

  let dateFormat = 'dd.MM HH:mm';
  if (
    format(new Date(), 'yyyy') !== format(new Date(match.startDate), 'yyyy')
  ) {
    dateFormat = 'dd.MM.yyyy HH:mm';
  }

  const {
    homeTeamGoals: homeTeamPenaltyGoals,
    awayTeamGoals: awayTeamPenaltyGoals,
  } = getShootoutGoals();

  const penaltyPhase = getProp(phases || {}, ['shootout']);

  if (view === 'full') {
    const homeTeam = getTeam('home');
    const awayTeam = getTeam('away');
    const { homeTeamGoals, awayTeamGoals } = getGoalsByTeams();

    const homeTeamOrganization =
      homeTeam?.organization || match?.teams?.[0]?.organization;
    const homeTeamLogoSrc =
      homeTeamOrganization && homeTeamOrganization._id
        ? getTeamLogoSrcFunc(match.appSpace, homeTeam)
        : FALLBACK_LOGO_IMAGE;

    const awayTeamOrganization =
      awayTeam?.organization || match?.teams?.[1]?.organization;
    const awayTeamLogoSrc =
      awayTeamOrganization && awayTeamOrganization._id
        ? getTeamLogoSrcFunc(match.appSpace, awayTeam)
        : FALLBACK_LOGO_IMAGE;

    return (
      <FullWidthScore>
        <FullWidthScoreLine>
          <FullWidthScoreLineTeam>
            <FullWidthTeamLogo>
              <Image
                fallbackSrc={FALLBACK_LOGO_IMAGE}
                alt={getProp(
                  homeTeam,
                  ['organization', 'name'],
                  getProp(match, ['teams', 0, 'organization', 'name'])
                )}
                src={homeTeamLogoSrc}
              />
            </FullWidthTeamLogo>
            <FullWidthScoreLineTeamName>
              {homeTeam
                ? homeTeam.organization?.name
                : getProp(match, ['teams', 0, 'organization', 'name'])}
              {homeTeam &&
                homeTeam.additionalProperties &&
                homeTeam.additionalProperties.resignation && (
                  <StrechResignation>(odstúpené)</StrechResignation>
                )}
            </FullWidthScoreLineTeamName>
          </FullWidthScoreLineTeam>
          <FullWidthScoreItems>
            {renderCompetitionName()}
            <div style={{ whiteSpace: 'nowrap' }}>
              <span>{homeTeamGoals}</span> - <span>{awayTeamGoals}</span>
            </div>
            {getFirstHalfTimeScore()}
            {(!!homeTeamPenaltyGoals || !!awayTeamPenaltyGoals) && (
              <PenaltyScore>
                {!!penaltyPhase && penaltyPhase.label}
                <br />
                {`${homeTeamPenaltyGoals}:${awayTeamPenaltyGoals}`}
              </PenaltyScore>
            )}
            <Label center style={{ textAlign: 'center' }}>
              {renderMatchStatus()}
            </Label>
          </FullWidthScoreItems>
          <FullWidthScoreLineTeam>
            <FullWidthTeamLogo>
              <Image
                fallbackSrc={FALLBACK_LOGO_IMAGE}
                alt={getProp(
                  awayTeam,
                  ['organization', 'name'],
                  getProp(match, ['teams', 1, 'organization', 'name'])
                )}
                src={awayTeamLogoSrc}
              />
            </FullWidthTeamLogo>
            <FullWidthScoreLineTeamName>
              {getTeamNameFunc(match.appSpace, awayTeam)}
              {awayTeam &&
                awayTeam.additionalProperties &&
                awayTeam.additionalProperties.resignation && (
                  <StrechResignation>(odstúpené)</StrechResignation>
                )}
            </FullWidthScoreLineTeamName>
          </FullWidthScoreLineTeam>
        </FullWidthScoreLine>
      </FullWidthScore>
    );
  } else if (view === 'streched') {
    const homeTeam = getTeam('home');
    const awayTeam = getTeam('away');
    const { homeTeamGoals, awayTeamGoals } = getGoalsByTeams();

    const homeTeamOrganization =
      homeTeam?.organization || match?.teams?.[0]?.organization;
    const homeTeamLogoSrc =
      homeTeamOrganization && homeTeamOrganization._id
        ? getTeamLogoSrcFunc(match.appSpace, homeTeam)
        : FALLBACK_LOGO_IMAGE;

    const awayTeamOrganization =
      awayTeam?.organization || match?.teams?.[1]?.organization;
    const awayTeamLogoSrc =
      awayTeamOrganization && awayTeamOrganization._id
        ? getTeamLogoSrcFunc(match.appSpace, awayTeam)
        : FALLBACK_LOGO_IMAGE;

    return (
      <MatchDetailLink isLive={!!isLive()} href={detailUrl}>
        <Row>
          <Label>
            {!!showCompetitionName && match.competition.name}{' '}
            {match.startDate
              ? format(new Date(match.startDate), dateFormat)
              : '-'}
          </Label>
          <StrechWidthScore>
            <StrechWidthTeam
              highlighted={!!homeTeamGoals && homeTeamGoals > awayTeamGoals}
            >
              <StrechWidthTeamName>
                {getTeamNameFunc(match.appSpace, homeTeam)}

                {homeTeam &&
                  homeTeam.additionalProperties &&
                  homeTeam.additionalProperties.resignation && (
                    <StrechResignation>(odstúpené)</StrechResignation>
                  )}
              </StrechWidthTeamName>
              <WinnerIconWrapper align="left">
                {!!(homeTeamGoals && homeTeamGoals > awayTeamGoals) ? (
                  <Icon
                    size="xs"
                    name="arrowWinner"
                    color={theme.app.textColor}
                  />
                ) : (
                  <span />
                )}
              </WinnerIconWrapper>
            </StrechWidthTeam>
            <ScoreLine>
              <div>
                <StrechWidthTeamLogo>
                  <Image
                    alt={getProp(
                      homeTeam,
                      ['organization', 'name'],
                      getProp(match, ['teams', 0, 'organization', 'name'])
                    )}
                    src={homeTeamLogoSrc}
                    fallbackSrc={FALLBACK_LOGO_IMAGE}
                  />
                </StrechWidthTeamLogo>
              </div>
              <div style={{ textAlign: 'center' }}>
                {!isFutureMatch() && (
                  <ScoreItem
                    highlighted={
                      !!homeTeamGoals && homeTeamGoals > awayTeamGoals
                    }
                  >
                    {homeTeamGoals}
                  </ScoreItem>
                )}{' '}
                -{' '}
                {!isFutureMatch() && (
                  <ScoreItem
                    highlighted={
                      !!awayTeamGoals && awayTeamGoals > homeTeamGoals
                    }
                  >
                    {awayTeamGoals}
                  </ScoreItem>
                )}
              </div>
              <div>
                <StrechWidthTeamLogo>
                  <Image
                    fallbackSrc={FALLBACK_LOGO_IMAGE}
                    alt={getProp(
                      awayTeam,
                      ['organization', 'name'],
                      getProp(match, ['teams', 1, 'organization', 'name'])
                    )}
                    src={awayTeamLogoSrc}
                  />
                </StrechWidthTeamLogo>
              </div>
            </ScoreLine>
            <StrechWidthTeam
              right
              highlighted={!!awayTeamGoals && awayTeamGoals > homeTeamGoals}
            >
              <WinnerIconWrapper align="right">
                {!!(awayTeamGoals && awayTeamGoals > homeTeamGoals) ? (
                  <Icon
                    size="xs"
                    name="arrowWinner"
                    color={theme.app.textColor}
                  />
                ) : (
                  <span />
                )}
              </WinnerIconWrapper>
              <StrechWidthTeamName>
                {getTeamNameFunc(match.appSpace, awayTeam)}
                {awayTeam &&
                  awayTeam.additionalProperties &&
                  awayTeam.additionalProperties.resignation && (
                    <StrechResignation>(odstúpené)</StrechResignation>
                  )}
              </StrechWidthTeamName>
            </StrechWidthTeam>
          </StrechWidthScore>
          <Label style={{ textAlign: 'right' }}>{renderMatchStatus()}</Label>
        </Row>
      </MatchDetailLink>
    );
  } else if (view === 'compact') {
    return (
      <Wrapper>
        <CompactMatchDetailLink isLive={!!isLive()} href={detailUrl}>
          <MatchStatus>
            <DateInfo>
              {!!showCompetitionName && match.competition.name}{' '}
              {match.startDate
                ? format(new Date(match.startDate), dateFormat)
                : '-'}
            </DateInfo>
            <MatchStatusInfo>{renderMatchStatus()}</MatchStatusInfo>
          </MatchStatus>
          <ScoreTable>{renderTeamsScores()}</ScoreTable>
        </CompactMatchDetailLink>
      </Wrapper>
    );
  }
  return null;
};

export default withTheme(MatchPreview);
