import { rem } from 'polished';
import Button from '@sportnet/ui/lib/Button';
import React from 'react';
import __ from 'utilities/getText';
import appContext from 'context/appContenxt';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  margin: 0;
  padding: 0;
  min-height: ${rem(40)};
  min-width: ${rem(40)};
  height: 100%;
`;

export const SoundButton: React.FC = () => {
  const { toggleSoundOn, soundOn, soundNotification } =
    React.useContext(appContext);

  return (
    <StyledButton
      title={__('Zvukové notifikácie')}
      icon={soundOn ? 'volumeUp' : 'volumeOff'}
      onClick={async () => {
        if (toggleSoundOn) {
          toggleSoundOn();
          // kvoli Safari sa po prepnuti zvuku do `On` musi sample zvuku prehrat,
          // aby sa prehraval aj nadalej v procese behu aplikacie.
          // NOTE: Play sa musi udiat priamo v onClick evente.
          // (https://webkit.org/blog/6784/new-video-policies-for-ios/)
          // Pri zapnuti zvuku mame este v `sounOn` kontextovej premennej
          // staru hodnotu. Preto pri zapnuti checkujeme `!soundOn`.
          if (soundNotification && !soundOn) {
            try {
              soundNotification.pause();
              soundNotification.currentTime = 0;
              await soundNotification.play();
            } catch (err) {
              console.error(err);
            }
          }
        }
      }}
    />
  );
};

export default React.memo(SoundButton);
