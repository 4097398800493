import CompetitionsApi from './api/CompetitionsApi';

const setupApis = () => {
  if (process.env.REACT_APP_COMPETITIONS_API_BASE_URL) {
    CompetitionsApi.setBaseUrl(process.env.REACT_APP_COMPETITIONS_API_BASE_URL);
  } else {
    throw Error('REACT_APP_COMPETITIONS_API_BASE_URL not set');
  }
};

export default setupApis;
