import { DatePicker } from '@sportnet/ui/lib/DatePickers';
import { IMatchesList } from '@sportnet/content/lib/library/Competitions';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import { theme } from 'theme/theme';
import { useHistory, useLocation } from 'react-router';
import Button from '@sportnet/ui/lib/Button';
import HeaderBar from '@sportnet/ui/lib/HeaderBar';
import Icon from '@sportnet/ui/lib/Icon';
import MatchesList from 'components/MatchesList/MatchesList';
import Message from '@sportnet/ui/lib/Message';
import PageContent from 'components/Layout/PageContent';
import QueryLoader from 'components/QueryLoader';
import React from 'react';
import Segment from '@sportnet/ui/lib/Segment';
import SoundButton from 'components/SoundButton';
import __ from 'utilities/getText';
import addDays from 'date-fns/addDays';
import appContext from 'context/appContenxt';
import format from 'date-fns/format';
import getApiErrorMessage from 'utilities/getApiErrorMessage';
import getDateFromDayString from 'utilities/getDateFromDayString';
import moment from 'moment';
import styled from 'styled-components';
import subDays from 'date-fns/subDays';
import useMatchesListForDay from 'queries/useMatchesListForDay';
import useQueryHoc, { StringParam } from 'query-hoc/lib/useQuery';

const FilterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: ${rem(16)};
`;

const DatePickerWrapper = styled.div`
  margin: 0 ${rem(4)};

  ${mb('s')} {
    width: ${rem(200)};
    flex: 0 0 auto;
  }
`;

const ArrowButton = styled.button`
  width: ${rem(40)};
  height: ${rem(40)};
  border: 1px solid ${theme.input.borderColor};
  background-color: white;
  border-radius: ${({ theme }) => rem(theme.input.borderRadius)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > * {
    pointer-events: none;
  }
`;

const LoadMoreButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${rem(20)};
`;

const QUERY_CONFIG = {
  parameters: {
    date: StringParam(''),
  },
};

export const MatchesListPage: React.FC = () => {
  const [day, setDay] = React.useState<Date | null>(null);
  const { search, pathname } = useLocation();
  const history = useHistory();
  const { soundNotification } = React.useContext(appContext);

  const { query, setQuery } = useQueryHoc(
    search,
    (newSearch) => {
      history.push({
        pathname,
        search: newSearch,
      });
    },
    QUERY_CONFIG
  );

  React.useEffect(() => {
    if (query.date) {
      const date = getDateFromDayString(query.date);
      if (date) {
        setDay(date);
      } else {
        setQuery({ date: format(new Date(), 'yyy-MM-dd') });
      }
    } else {
      setDay(new Date());
    }
  }, [query, setQuery]);

  // main query na zoznam zapasov
  const {
    data: response,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    error,
    status,
  } = useMatchesListForDay(day);

  const handleArrowButtonClick = (side: 'left' | 'right') => {
    switch (side) {
      case 'left': {
        setQuery({ date: format(subDays(day ?? new Date(), 1), 'yyyy-MM-dd') });
        break;
      }
      case 'right': {
        setQuery({ date: format(addDays(day ?? new Date(), 1), 'yyyy-MM-dd') });
        break;
      }
      default:
        void 0;
    }
  };

  const hasData = response?.pages[0];
  const noMatchesFound = hasData && !(response?.pages[0]?.total ?? 0);

  return (
    <PageContent
      title={__('Zoznam dostupných zápasov')}
      restHeaderbarElements={
        <>
          <HeaderBar.Spacer />
          {soundNotification && <SoundButton />}
        </>
      }
    >
      <Segment>
        <FilterWrapper>
          <ArrowButton
            onClick={() => {
              handleArrowButtonClick('left');
            }}
          >
            <Icon name="arrow-left" size="l" />
          </ArrowButton>
          <DatePickerWrapper>
            <DatePicker
              value={moment(getDateFromDayString(query.date) ?? new Date())}
              onChange={(newDay: moment.Moment | null) => {
                setQuery({
                  date: format(newDay?.toDate() ?? new Date(), 'yyyy-MM-dd'),
                });
              }}
            />
          </DatePickerWrapper>
          <ArrowButton
            name="right"
            onClick={() => {
              handleArrowButtonClick('right');
            }}
          >
            <Icon name="arrow-right" size="l" />
          </ArrowButton>
        </FilterWrapper>
        {hasData ? (
          <>
            {noMatchesFound ? (
              <Message
                primary
                title={__('Pre zvolený deň neboli nájdené žiadne zápasy.')}
              ></Message>
            ) : (
              <>
                {response?.pages.map((page, idx) => {
                  return (
                    <MatchesList
                      key={`page-${idx}`}
                      matchesList={page as unknown as IMatchesList}
                      theme={theme}
                      showCompetitionName={true}
                      matchDetailUrl={(matchId: string) => {
                        return `/match/${matchId}${
                          query.date && `?date=${query.date}`
                        }`;
                      }}
                    />
                  );
                })}

                {hasNextPage && (
                  <LoadMoreButtonWrapper>
                    <Button
                      primary
                      onClick={() => fetchNextPage()}
                      disabled={!hasNextPage || isFetchingNextPage}
                    >
                      {isFetchingNextPage
                        ? __('Načítavam...')
                        : hasNextPage
                        ? __('Zobraziť viac')
                        : ''}
                    </Button>
                  </LoadMoreButtonWrapper>
                )}
              </>
            )}
          </>
        ) : status === 'loading' ? (
          <QueryLoader />
        ) : status === 'error' ? (
          <Message danger title={__('Chyba pri načítaní zoznamu zápasov!')}>
            {getApiErrorMessage(error)}
          </Message>
        ) : (
          <></>
        )}
      </Segment>
    </PageContent>
  );
};

export default React.memo(MatchesListPage);
