import * as React from 'react';
import {
  IMatchesList,
  IWidgetMatchesListMatchItem,
} from '@sportnet/content/lib/library/Competitions';
import { ITheme } from '@sportnet/content/lib/theme/theme';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import MatchPreview from './preview';
import format from 'date-fns/format';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: ${rem(12)};
  ${({ theme }) => css`
    margin: 0 -${rem(theme.grid.gutterWidth / 2)};
    width: calc(100% + ${rem(theme.grid.gutterWidth)});
  `}
  ${mb('m')} {
    margin: 0;
    width: 100%;
  }
`;

const MatchWrapper = styled.div`
  padding: ${({ theme }) =>
    `${rem(theme.grid.gutterWidth / 4)} ${rem(theme.grid.gutterWidth / 2)}`};
`;

const MatchesWrapper = styled.div`
  > div:nth-child(odd) {
    background: #f9f9f9;
  }
  > div:nth-child(even) {
    background: #fff;
  }
`;
const RoundNameHeader = styled.div`
  font-size: ${rem(12)};
  padding: ${rem(3)} ${rem(6)};
  background-color: #f1f1f1;
  font-weight: bold;
  min-height: ${rem(30)};
  display: flex;
  align-items: center;
`;

const MacthesRoundListWrapper = styled.div`
  > .content-widget-matchesList-roundName:not(:first-child):not(:last-child) {
    margin-bottom: ${rem(1)};
    margin-top: ${rem(1)};
  }

  > .content-widget-matchesList-roundName:first-child {
    margin-bottom: ${rem(1)};
  }

  > .content-widget-matchesList-roundName:last-child {
    margin-top: ${rem(1)};
  }
`;

const defaultSize = {
  innerHeight: 0,
  innerWidth: 0,
  outerHeight: 0,
  outerWidth: 0,
};

function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState(defaultSize);

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
        outerHeight: window.outerHeight,
        outerWidth: window.outerWidth,
      });
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}

function useIsResponsiveLayout(breakpoint = 768) {
  const resizeHook = useWindowSize();

  const mobileLayout = React.useMemo(() => {
    return resizeHook.innerWidth ? resizeHook.innerWidth <= breakpoint : false;
  }, [resizeHook.innerWidth, breakpoint]);

  return mobileLayout;
}

interface IOwnProps {
  matchesList: IMatchesList;
  groupByRound?: boolean;
  showCompetitionName?: boolean;
  matchDetailUrl?: (matchId: string, issfId?: number) => string;
}

type Props = { theme: ITheme } & IOwnProps;

const MatchesList: React.FC<Props> = ({
  theme,
  matchesList,
  groupByRound,
  showCompetitionName,
  matchDetailUrl,
}) => {
  const mobileLayout = useIsResponsiveLayout();

  const matchesByRound = groupByRound
    ? matchesList.matches.reduce(
        (acc: { [key: string]: IWidgetMatchesListMatchItem[] }, m) => {
          let key = 'unknown';
          if (m.round) {
            key = `${format(
              m.round.dateFrom ? new Date(m.round.dateFrom) : new Date(),
              'yyyy-MM-dd'
            )}|${m.competitionPart.name}|${m.round.name}`;
          }
          if (key in acc) {
            acc[key].push(m);
          } else {
            acc[key] = [m];
          }
          return acc;
        },
        {}
      )
    : {};

  const renderRoundsList = () =>
    Object.keys(matchesByRound).map((roundId) => {
      const [, competitionName = '', roundName = ''] = roundId.split('|');
      return (
        <React.Fragment key={roundId}>
          <RoundNameHeader className="content-widget-matchesList-roundName">
            {competitionName &&
              roundName &&
              `${competitionName} - ${roundName}`}
          </RoundNameHeader>
          <MatchesWrapper>
            {matchesByRound[roundId].map((match) => {
              return (
                <MatchWrapper key={`match-${match._id}`}>
                  <MatchPreview
                    view={!mobileLayout ? 'streched' : 'compact'}
                    theme={theme}
                    match={match}
                    detailUrl={
                      matchDetailUrl
                        ? matchDetailUrl(match._id, match.__issfId)
                        : ''
                    }
                  />
                </MatchWrapper>
              );
            })}
          </MatchesWrapper>
        </React.Fragment>
      );
    });

  const renderMatchesList = () => (
    <MatchesWrapper>
      {matchesList.matches.map((match) => (
        <MatchWrapper key={`match-${match._id}`}>
          <MatchPreview
            view={!mobileLayout ? 'streched' : 'compact'}
            theme={theme}
            match={match}
            showCompetitionName={showCompetitionName}
            detailUrl={
              matchDetailUrl ? matchDetailUrl(match._id, match.__issfId) : ''
            }
          />
        </MatchWrapper>
      ))}
    </MatchesWrapper>
  );

  return (
    <Wrapper>
      {groupByRound ? (
        <MacthesRoundListWrapper>{renderRoundsList()}</MacthesRoundListWrapper>
      ) : (
        renderMatchesList()
      )}
    </Wrapper>
  );
};

export default MatchesList;
