import { useQuery } from 'react-query';
import CompetitionsApi from 'api/CompetitionsApi';

export default function useSettingsById(settingId: string) {
  return useQuery(
    ['settings', settingId],
    () => {
      return CompetitionsApi.getSettingById(settingId) as Promise<{
        _id: string;
        sport_sectors: any[];
      }>;
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
}
