import {
  IMatchSettings,
  IWidgetMatchesListMatchItem,
} from '@sportnet/content/lib/library/Competitions';
import { IVarEvent } from 'library/Competitions';
import { deserializeQuery } from 'query-hoc/lib/useQuery';
import { rem } from 'polished';
import { theme } from 'theme/theme';
import { useLocation, useParams } from 'react-router';
import Header from '@sportnet/ui/lib/Header';
import HeaderBar from '@sportnet/ui/lib/HeaderBar';
import MatchOverview from '@sportnet/content/lib/view/widgets/MatchOverview/Match';
import MatchVarEventsList from 'components/MatchVarEventsList';
import Message from '@sportnet/ui/lib/Message';
import PageContent from 'components/Layout/PageContent';
import QueryLoader from 'components/QueryLoader';
import React from 'react';
import Segment from '@sportnet/ui/lib/Segment';
import SoundButton from 'components/SoundButton';
import VarEventsListener from 'library/VarEventsListener';
import __ from 'utilities/getText';
import appContext from 'context/appContenxt';
import compareDesc from 'date-fns/compareDesc';
import getApiErrorMessage from 'utilities/getApiErrorMessage';
import styled from 'styled-components';
import useMatchById from 'queries/useMatchById';
import useMatchVarEvents from 'queries/useMatchVarEvents';
import useSettingsById from 'queries/useSettingsById';

const MatchVarEventsWrapper = styled.div`
  padding: ${rem(16)};
`;

interface RouteParams {
  matchId: string;
}

export const MatchDetailPage: React.FC = () => {
  const { matchId } = useParams<RouteParams>();
  const [varEvents, setVarEvents] = React.useState<IVarEvent[] | null>(null);
  const { search } = useLocation();

  const { soundOn, soundNotification } = React.useContext(appContext);
  const varpubClient = React.useRef<VarEventsListener | null>(null);

  const { date = '' } = deserializeQuery(search);

  // query na settings (cache je nastavena na Infinity)
  const {
    data: settings,
    error: settingsError,
    status: settingsStatus,
  } = useSettingsById('sport_sector_phases');

  // query na detail zapasu
  const { data: match, error, status } = useMatchById(matchId);

  // query na events
  const {
    data: matchVarEvents,
    error: matchVarEventsError,
    status: matchVarEventsStatus,
  } = useMatchVarEvents(matchId);

  // pripojenie websocketu
  React.useEffect(() => {
    if (!matchId) {
      return;
    }
    const varpub = new VarEventsListener({
      debug: process.env.NODE_ENV === 'development',
      connect: true,
      matchIds: [matchId],
    });
    varpubClient.current = varpub;
    return () => {
      varpub.close();
    };
  }, [matchId, varpubClient]);

  // pripojenie handlera na prijmanie sprav z websocket
  // NOTE: handler je zavisly na premennej `soundOn` a je oddeleny
  // v samostatnom `useEffect` aby sa predislo znovupripajaniu
  // na Websocket.
  React.useEffect(() => {
    if (!varpubClient.current) {
      return;
    }

    varpubClient.current.off('varEvent');
    varpubClient.current.on('varEvent', (wsMatchId, event) => {
      if (matchId === wsMatchId) {
        // zvukova notifikacia
        if (soundNotification && soundOn) {
          soundNotification.pause();
          soundNotification.currentTime = 0;
          const playPromise = soundNotification.play();
          if (playPromise !== undefined) {
            playPromise
              .catch((e) => {
                console.warn(e);
              })
              .then(() => {});
          }
        }
        // setnutie eventov
        setVarEvents((prev) => {
          const newState = [event, ...(prev || [])].sort((a, b) => {
            return compareDesc(new Date(a.datetime!), new Date(b.datetime!));
          });
          return newState;
        });
      }
    });
  }, [varpubClient, setVarEvents, matchId, soundOn, soundNotification]);

  React.useEffect(() => {
    if (matchVarEventsStatus === 'success') {
      // sort match events by datetime
      const events = matchVarEvents?.varEvents || [];
      events.sort((a, b) => {
        return compareDesc(new Date(a.datetime!), new Date(b.datetime!));
      });
      setVarEvents(events);
    } else {
      setVarEvents(null);
    }
  }, [matchVarEvents, matchVarEventsStatus]);

  const sportSectorPhases = settings ? settings.sport_sectors : [];

  const renderMatchVarEvents = () => (
    <>
      {varEvents ? (
        <>
          {varEvents.length > 0 ? (
            <MatchVarEventsWrapper>
              <MatchVarEventsList varEvents={varEvents} />
            </MatchVarEventsWrapper>
          ) : (
            <Message
              primary
              title={__('V zápase nie sú evidované žiadne VAR udalosti.')}
            >
              {__(
                'Stránka sa obnovuje automaitcky bez potreby znovunačítania.'
              )}
            </Message>
          )}
        </>
      ) : matchVarEventsStatus === 'error' ? (
        <Message danger title={__('Chyba pri načítaní VAR udalostí!')}>
          {getApiErrorMessage(matchVarEventsError)}
        </Message>
      ) : matchVarEventsStatus === 'loading' ? (
        <QueryLoader />
      ) : null}
    </>
  );

  return (
    <PageContent
      title={__('Detail zápasu')}
      linkBack={`/matches${date && `?date=${date}`}`}
      restHeaderbarElements={
        <>
          <HeaderBar.Spacer />
          {soundNotification && <SoundButton />}
        </>
      }
    >
      {settingsStatus === 'success' && settings ? (
        <>
          {match ? (
            <>
              <Segment>
                <MatchOverview
                  match={match as unknown as IWidgetMatchesListMatchItem}
                  lastIndex
                  fullWidth
                  sportSectorsPhases={sportSectorPhases}
                  theme={theme}
                  settings={match.settings as IMatchSettings}
                  verbose
                />
              </Segment>
              <Segment>
                <Header withSeparator size="s">
                  {__('Udalosti VAR')}
                </Header>
                {renderMatchVarEvents()}
              </Segment>
            </>
          ) : status === 'loading' ? (
            <QueryLoader />
          ) : status === 'error' ? (
            <Message danger title={__('Chyba pri načítaní detailu zápasu!')}>
              {getApiErrorMessage(error)}
            </Message>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {settingsStatus === 'error' ? (
            <Message danger title={__('Chyba pri načítaní dát z číselníka!')}>
              {getApiErrorMessage(settingsError)}
            </Message>
          ) : settingsStatus === 'loading' ? (
            <QueryLoader />
          ) : (
            <></>
          )}
        </>
      )}
    </PageContent>
  );
};

export default React.memo(MatchDetailPage);
