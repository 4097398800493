import { useQuery } from 'react-query';
import CompetitionsApi from 'api/CompetitionsApi';

export default function useMatchById(matchId: string) {
  return useQuery(
    ['match-detail', matchId],
    () => {
      return CompetitionsApi.getMatch(matchId, { noCache: 1 });
    },
    {
      enabled: !!matchId,
      refetchInterval: 30 * 1000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: 'always',
    }
  );
}
